import React from 'react';
import QrCodeScanner from './QrCodeScanner';

function App() {
  return (
    <div className="App">
      <QrCodeScanner />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import {Scanner as QrScanner, boundingBox } from '@yudiel/react-qr-scanner';

const QrCodeScanner = () => {
  const [qrCodeData, setQrCodeData] = useState('');
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(null);
  const [isScanning, setIsScanning] = useState(true);
  const [constraints, setConstraints] = useState({ facingMode: 'environment' });

  useEffect(() => {
    async function setupCamera() {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        try {
          const devices = await navigator.mediaDevices.enumerateDevices();
          const videoDevices = devices.filter(device => device.kind === 'videoinput');
          if (videoDevices.length > 0) {
            const rearCamera = videoDevices.find(device => device.label.toLowerCase().includes('back'));
            if (rearCamera) {
              setConstraints({ deviceId: rearCamera.deviceId });
            } else {
              const frontCamera = videoDevices.find(device => device.label.toLowerCase().includes('front'));
              if (frontCamera) {
                setConstraints({ deviceId: frontCamera.deviceId });
              } else {
                setConstraints({ deviceId: videoDevices[0].deviceId });
              }
            }
          }
        } catch (err) {
          console.error('Error accessing media devices.', err);
          setConstraints({ facingMode: 'user' }); // Fallback to front camera
        }
      } else {
        console.error('Media devices API not supported.');
        setError('Il dispositivo non supporta la fotocamera.');
      }
    }

    setupCamera();
  }, []);

  const handleScan = async (data) => {
    if (data && isScanning) {
      setIsScanning(false); // Disable scanning
      setLoading(true);
      setError('');
      try {
        const decodedData = JSON.parse(atob(data));
        const username = decodedData.username;

        const response = await fetch(`https://hermesclub.it/wp-json/custom/v1/userdata?username=${username}`);
        if (!response.ok) {
          throw new Error('Impossibile recuperare i dati dell\'utente');
        }

        const userData = await response.json();
        userData.birth_date = formatDate(userData.birth_date);
        userData.age = calculateAge(userData.birth_date);
        setUserData(userData);
        setValid(userData.qr_code_valid);
      } catch (err) {
        setError('Impossibile decodificare il QR code o recuperare i dati dell\'utente');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleError = (err) => {
    setError('Errore del lettore QR');
    console.error(err);
  };

  const formatDate = (dateString) => {
    const datePatterns = [
      /^\d{1,2}\/\d{1,2}\/\d{2,4}$/,
      /^\d{2}\.\d{4}\/\d{2}\/\d{4}$/
    ];
    for (const pattern of datePatterns) {
      if (pattern.test(dateString)) {
        const parts = dateString.split(/[\/\.]/);
        return parts.map(part => part.padStart(2, '0')).join('/');
      }
    }
    return dateString;
  };

  const calculateAge = (birthDateString) => {
    const today = new Date();
    const birthDateParts = birthDateString.split('/');
    const birthDate = new Date(
      birthDateParts[2].length === 2 ? `20${birthDateParts[2]}` : birthDateParts[2], 
      birthDateParts[1] - 1, 
      birthDateParts[0]
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const IScannerStyles = {
    container: {width:250,height:250},
    video: {borderRadius:15}
  }

  const IScannerComponents = {
    audio: true,
    onOff: false,
    torch: true,
    finder: false,
    tracker: boundingBox
  }

  const containerStyle = {
    backgroundColor: valid === null ? 'gray' : valid ? 'green' : 'red',
    transition: 'background-color 0.5s ease',
    padding: '10px',
  };

  const spinnerStyle = {
    border: '4px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '50%',
    borderTop: '4px solid black',
    width: '20px',
    height: '20px',
    animation: 'spin 1s linear infinite',
  };

  const retryScan = () => {
    setIsScanning(true);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen" style={containerStyle}>
      <h1 className="text-3xl font-bold mb-4 text-white">HERMES CLUB SCANNER</h1>
      {error ? (
        <div className="mt-4 p-4 bg-red-200 text-red-800 rounded shadow">
          <p className="text-lg">{error}</p>
        </div>
      ) : (
        <QrScanner
          formats={["qr_code"]}
          allowMultiple={true}
          scanDelay={1000}
          onScan={(result) => {
            console.log(result)
            if (result) {
              handleScan(result[0].rawValue);
            }
          }}
          videoConstraints={constraints}
          styles={IScannerStyles}
          components={IScannerComponents}
        />
      )}
      {loading && (
        <div className="mt-4 p-4 bg-white rounded shadow flex items-center">
          <div style={spinnerStyle}></div>
          <p className="text-lg ml-4">Caricamento...</p>
        </div>
      )}
      {error && !loading && (
        <div className="mt-4 p-4 bg-red-200 text-red-800 rounded shadow flex items-center justify-between">
          <p className="text-lg">{error}</p>
          <button onClick={retryScan} className="bg-red-500 text-white px-3 py-1 rounded">Riprova</button>
        </div>
      )}
      {userData && (
        <div className="text-white mt-4 p-4 flex flex-col items-center">
          <p className="text-3xl font-bold">{userData.first_name} {userData.last_name}</p>
          <p>{userData.email}</p>
          <div className="mt-10">
            <p className="text-xl font-semibold">{userData.birth_date} ({userData.age} anni)</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default QrCodeScanner;

